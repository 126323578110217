import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import "./VideoEX.scss";

import { handleExVideos } from "utils/exercises";
import { RootState } from "modules";
import { useFailure, useSaveresult, useHealthmovies } from "apis/exerciseApi";

import { ReactComponent as XIcon } from "assets/svg/ic_background_x.svg";
import ThumbImg from "assets/img/library_thumb_bridge.png";

const VideoEXStart = () => {
  const [videos, setVideos] = useState<any>([]);

  const store = useSelector((state: RootState) => state);
  const { usridx, creatorIdx } = store.local;

  const navigate = useNavigate();
  const { search } = useLocation();
  const week = new URLSearchParams(search).get("week") as string;
  const day = new URLSearchParams(search).get("day") as string;
  const programIdx = new URLSearchParams(search).get("_id") as string;
  const playerRef = useRef<HTMLVideoElement>(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [playTime, setPlayTime] = useState(0);
  const [count, setCount] = useState(0);
  const [failLibraryidx, setFailLibraryidx] = useState(0);
  const [giveup, setGiveup] = useState("Y");
  const [libraryData, setLibraryData] = useState<{ [key: string]: number }[]>(
    []
  );
  // const [isMuted, setIsMuted] = useState(false);

  const useHealthmoviesApi = useHealthmovies({
    programIdx: programIdx,
    usridx: creatorIdx,
    week: Number(week),
    day: Number(day),
  });

  const useFailureApi = useFailure({
    programIdx: programIdx,
    usridx: usridx,
    week: Number(week),
    day: Number(day),
    failLibraryidx: failLibraryidx,
    category: 2,
    libraryGroup: useHealthmoviesApi.data?.exerciseMovieList?.libraryGroup,
  });

  const useSaveresultApi = useSaveresult({
    usridx: usridx,
    programIdx: programIdx,
    giveup: giveup,
    week: Number(week),
    day: Number(day),
    exerciseTime: 1200,
    heartrate: null,
    heartrateinfo:
      '{"IheartrateInfo":null,"BheartrateInfo":null,"dataname":"n"}',
    totalCalorie: 2600,
    libraryData: libraryData,
    grade: "A",
    category: 2,
    libraryGroup: useHealthmoviesApi.data?.exerciseMovieList?.libraryGroup,
    gender: useHealthmoviesApi.data?.exerciseMovieList?.gender,
  });

  useEffect(() => {
    useHealthmoviesApi.refetch();
    return () => {
      useFailureApi.remove();
    };
  }, []);

  //api
  useEffect(() => {
    const { data, isSuccess } = useHealthmoviesApi;
    if (isSuccess && data?.result === "success") {
      const { exerciseMovieList } = data;
      if (exerciseMovieList?.exerciseProgramInfo) {
        console.log(
          handleExVideos(
            exerciseMovieList?.exerciseProgramInfo,
            useHealthmoviesApi.data?.exerciseMovieList?.libraryGroup
          )
        );
        setVideos(
          handleExVideos(
            exerciseMovieList?.exerciseProgramInfo,
            useHealthmoviesApi.data?.exerciseMovieList?.libraryGroup
          )
        );
      } else {
        navigate(-1);
      }
    }
  }, [useHealthmoviesApi.data, useHealthmoviesApi.isSuccess]);

  useEffect(() => {
    const { data, isSuccess } = useFailureApi;
    if (isSuccess && data?.result === "success") {
      navigate(-1);
    }
  }, [useFailureApi.data, useFailureApi.isSuccess]);

  //영상 재생 시간 or 수 체크
  useEffect(() => {
    if (videos.length > 0) {
      if (currentVideoIndex === videos.length) {
        navigate(-1);
      } else {
        const player = playerRef?.current;

        // player가 유효하고, 현재 일시정지 상태일 때만 play() 호출
        if (player && player.paused) {
          player.play().catch((error) => {
            console.error("Auto-play failed:", error.message);
          });
        }
      }
    }
  }, [currentVideoIndex, videos, navigate]);

  useEffect(() => {
    if (videos[currentVideoIndex] && videos[currentVideoIndex]?.type === 1) {
      setPlayTime(playTime + 1);
    }
  }, [currentTime]);

  useEffect(() => {
    if (
      videos[currentVideoIndex] &&
      videos[currentVideoIndex]?.type === 1 &&
      videos[currentVideoIndex]?.time === playTime
    ) {
      handleNextVideo();
    }
  }, [playTime]);

  useEffect(() => {
    if (
      videos[currentVideoIndex] &&
      videos[currentVideoIndex]?.name === "exercise" &&
      (videos[currentVideoIndex]?.type === 2 ||
        videos[currentVideoIndex]?.type === 5) &&
      videos[currentVideoIndex]?.repeate + 1 === count
    ) {
      handleNextVideo();
    }
  }, [count]);

  useEffect(() => {
    if (giveup === "N") {
      useSaveresultApi.refetch();
    }
  }, [giveup]);

  const handleVideoEnded = () => {
    if (
      videos[currentVideoIndex] &&
      videos[currentVideoIndex]?.name !== "rest"
    ) {
      handleNextVideo();
    }
  };

  const handleTimeUpdate = () => {
    const playerRefTime = playerRef?.current?.currentTime || 0;
    const timeUpdate = Math.floor(playerRefTime % 60);

    if (videos[currentVideoIndex]?.type === 1) {
      setCurrentTime(timeUpdate);
    } else if (
      (videos[currentVideoIndex]?.type === 2 ||
        videos[currentVideoIndex]?.type === 5) &&
      videos[currentVideoIndex]?.name === "exercise" &&
      playerRefTime < 0.01
    ) {
      setCount(count + 1);
    }
  };

  const handleNextVideo = () => {
    const nextVideoIndex = currentVideoIndex + 1;
    setCurrentVideoIndex(nextVideoIndex);
    setPlayTime(0);
    setCount(0);

    if (
      videos[nextVideoIndex]?.name === "exercise" &&
      nextVideoIndex < videos.length
    ) {
      setFailLibraryidx(failLibraryidx + 1);
      const library = {
        libraryId: videos[nextVideoIndex].libraryId,
        calorie: videos[nextVideoIndex].calorie,
        goal: videos[nextVideoIndex].goal,
        real: videos[nextVideoIndex].goal,
      };
      const newLibrary = libraryData ? [...libraryData, library] : [library];
      setLibraryData(newLibrary);
    }

    if (nextVideoIndex === videos.length) {
      setGiveup("N");
    }
  };

  const onClickBack = () => {
    useSaveresultApi.refetch();
    useFailureApi.refetch();
  };

  // const toggleMute = () => {
  //   setIsMuted(!isMuted); // 소리 상태를 토글
  // };
  return (
    <>
      {/* {currentVideoIndex === videos.length ? (
        <VideoEXEnd />
      ) : ( */}
      <div className={`VideoEXStart`}>
        {/* <p><button onClick={toggleMute}>소리</button></p> */}
        <video
          ref={playerRef}
          src={videos[currentVideoIndex]?.url}
          onEnded={handleVideoEnded}
          width="100%"
          height="100%"
          controls
          autoPlay
          onTimeUpdate={handleTimeUpdate}
          loop={
            videos[currentVideoIndex]?.name !== "guide" &&
            videos[currentVideoIndex]?.name !== "start" &&
            videos[currentVideoIndex]?.name !== "end"
          }
          poster={ThumbImg}
          // muted={isMuted}
        />
        <p className={`Xicon`} onClick={onClickBack}>
          <XIcon />
        </p>
      </div>
      {/* )} */}
    </>
  );
};

export default VideoEXStart;
