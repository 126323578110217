import { store } from "../modules";
import { set_program_reload } from "../modules/common";
import { set_is_unity, set_exapparatus_login } from "../modules/local";
import i18next from "i18next";

export const handleReloadProgram = (reload: string) => {
  console.log("WEB handleReloadProgram 1:: ", reload);
  if (reload === "True") {
    console.log("WEB handleReloadProgram 2:: ", reload);
    store.dispatch(set_program_reload(reload));
    store.dispatch(set_is_unity(false));
  }
};

export const handleExapparatusLogin = (phonenumber: string) => {
  store.dispatch(set_exapparatus_login(phonenumber));
};

export const sendLanguage = (language: string) => {
  i18next.changeLanguage(language);
};
