import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./ProduceTable.scss";
import SortBtn from "components/butttons/sortBtn/SortBtn";
import RoundBtn from "components/butttons/roundBtn/RoundBtn";
import PageNav from "components/navigation/pageNav/PageNav";
import ImgLink from "components/commons/imgLink/ImgLink";
import NoData from "components/infos/noData/NoData";
import InputModal from "components/modal/inputModal/InputModal";
import VideoSymbol from "components/symbol/VideoSymbol";

import { RootState } from "modules";
import {
  set_alert2_modal,
  set_alert_modal,
  set_program_reload,
} from "modules/common";

//global
import {
  stringFormat,
  stringFormatArr,
  hoursSeconds,
  setNewDateFunc,
  searchGetPage,
  pageSearchAddFunc,
} from "utils/commons";
import {
  useStudioDeleteprogram,
  useStudioChecktrainer,
  useStudioProgramstate,
  usePhonechecke,
  useSendexercise,
} from "apis/studioApi";
import { cAPIMessagePack } from "utils/cAPI";
import { onlyNumberFunc } from "utils/validate";
import { phonenumberFunc } from "utils/commons";
import KEMASymbol from "components/symbol/KEMASymbol";
import SchoolSymbol from "components/symbol/SchoolSymbol";

interface SortType {
  date: number;
  time: number;
}

const ProduceList = ({ ...props }) => {
  const { t } = useTranslation();
  const { data, setReLoad } = props;
  const dispatch = useDispatch();
  const store = useSelector((state: RootState) => state);
  const usridx = store.local.usridx;
  const studioPrograms = store.local.studioPrograms || [1];
  const navigate = useNavigate();
  //api
  const deleteprogramParam = {
    _id: data._id,
  };
  const useStudioDeleteprogramApi = useStudioDeleteprogram(deleteprogramParam);
  const checktrainerParam = {
    usridx: usridx,
  };
  const useStudioChecktrainerApi = useStudioChecktrainer(checktrainerParam);

  useEffect(() => {
    const { isSuccess, isLoading } = useStudioDeleteprogramApi;
    setReLoad(!isLoading);
    if (isSuccess) {
      const url = pageSearchAddFunc(1);
      navigate(url);
    }
  }, [
    useStudioDeleteprogramApi.data,
    useStudioDeleteprogramApi.isSuccess,
    useStudioDeleteprogramApi.isLoading,
  ]);

  useEffect(() => {
    const { data, isSuccess, isLoading, remove } = useStudioChecktrainerApi;
    setReLoad(!isLoading);
    if (isSuccess && data?.result === "success") {
      if (data?.trainer === "true") {
        let alertModal = {
          onoff: true,
          title: "업로드",
          text: t("ExerciseProgramMaking_TestEvaluation_Desc"),
          btn1Text: t("ExerciseProgramMaking_TestEvaluation_Btn1"),
          btn2Text: t("ExerciseProgramMaking_TestEvaluation_Btn2"),
          okFunc: () =>
            cAPIMessagePack(
              `exbodyKiosk:?type=4&_id=${deleteprogramParam._id}&usridx=${usridx}`
            ),
        };
        dispatch(set_alert2_modal(alertModal));
      } else {
        let alertModal = {
          onoff: true,
          title: "업로드",
          text: t("ExerciseProgramMaking_TrainerRegisterPopup_Desc"),
        };
        dispatch(set_alert_modal(alertModal));
      }
      remove();
    }
  }, [
    useStudioChecktrainerApi.data,
    useStudioChecktrainerApi.isSuccess,
    useStudioChecktrainerApi.isLoading,
  ]);

  const handleClickDeleteBtn = () => {
    let alertModal = {
      onoff: true,
      title: t("ExerciseStudioPage_MakingList_DeletePopup_Title"),
      text: stringFormat(
        t("ExerciseStudioPage_MakingList_DeletePopup_Desc"),
        `<span className=blue>${data.title}</span>`
      ),
      // "삭제된 운동 프로그램은 복구가 불가능합니다. <span className=blue>하체 완벽 타파 운동</span> 프로그램을 삭제 하시겠습니까?",
      btn1Text: t("ExerciseStudioPage_MakingList_DeletePopup_Btn_1"),
      btn2Text: t("ExerciseStudioPage_MakingList_DeletePopup_Btn_2"),
      okFunc: handleClickOk,
    };
    return dispatch(set_alert2_modal(alertModal));
  };

  const handleClickOk = () => {
    useStudioDeleteprogramApi.refetch();
  };

  const handleSubmit = () => {
    props.handleSubmitModal(data);
  };

  return (
    <div className={`produceList`}>
      <ImgLink
        to={`/exercise/detail?excode=${data._id}&type=studio`}
        src={
          data.exerciseImage.indexOf("http") > -1
            ? data.exerciseImage
            : `../../../../exercise/${data.exerciseImage}.png`
        }
      />
      <div className={`title`}>
        <p className={`body_medium`}>{data.title}</p>
        {data.libraryGroup === 5 && <VideoSymbol />}
        {data?.libraryGroup === 6 && <KEMASymbol />}
        {data?.libraryGroup === 9 && <SchoolSymbol />}
      </div>
      <p className={"caption1_regular date"}>
        {stringFormatArr(t("ExerciseStudioPage_MakingList_Column_Value_1"), [
          setNewDateFunc(data.updateDate).year,
          setNewDateFunc(data.updateDate).month,
          setNewDateFunc(data.updateDate).day,
        ])}
      </p>
      <i className={`line`}></i>
      <p className={"caption1_regular time"}>
        {stringFormatArr(
          t("ExerciseStudioPage_MakingList_Column_Value_2"),
          hoursSeconds(data.myexerciseTime)
        )}
      </p>
      <div className={`btns`}>
        {/* 스튜디오 수정 */}
        {studioPrograms?.includes(data.libraryGroup) ? (
          <RoundBtn
            text={t("ExerciseStudioPage_MakingList_Btn_1")}
            onClick={() =>
              cAPIMessagePack(
                `exbodyKiosk:?type=3&_id=${data._id}&usridx=${usridx}&datatype=${data.libraryGroup}`
              )
            }
          />
        ) : (
          <RoundBtn
            text={t("ExerciseStudioPage_MakingList_Btn_1")}
            style={{ opacity: "0" }}
          />
        )}

        <RoundBtn
          text={t("ExerciseStudioPage_MakingList_Btn_3")}
          onClick={handleClickDeleteBtn}
        />
        {data.category > 1 && studioPrograms?.includes(data.libraryGroup) ? (
          <RoundBtn
            text={t("Studio_Exercise_Send_Button")}
            onClick={handleSubmit}
            typeClassName="submit"
          />
        ) : (
          <RoundBtn
            text={t("Studio_Exercise_Send_Button")}
            typeClassName="submit"
            style={{ opacity: "0" }}
          />
        )}
      </div>
    </div>
  );
};

const LodingProduceList = () => {
  return (
    <div className={`produceList listLoading`}>
      <p className={`produceListImg`}></p>
      <p className={`body_medium title`}>
        <span className={`no_data`}></span>
      </p>
      <p className={"caption1_regular date "}>
        <span className={`no_data`}></span>
      </p>
      <i className={`line`}></i>
      <p className={"caption1_regular time"}>
        <span className={`no_data`}></span>
      </p>
      <div className={`btns`}></div>
    </div>
  );
};

//main
const ProduceTable = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state: RootState) => state);
  const { headerInfo, usridx, studioPrograms, programidx } = store.local;
  const { programReload } = store.common;
  const companyIdx = store.local.companyIdx || 0;

  const navigate = useNavigate();
  const noDataArr = [{}, {}, {}, {}, {}];
  const [pageNavState, setPageNavState] = useState({
    page: 0,
    dateLength: 0,
  });
  const [clickPage, setClickPage] = useState(1);
  const [sortDatas, setSortDatas] = useState({
    date: 1,
    time: 0,
  });
  const [dataArr, setDataArr] = useState<any[] | null>(null);
  const [orderType, setOrderType] = useState("descupdateDate");
  const [isReLoad, setReLoad] = useState(false);
  const [submitModal, setSubmitModal] = useState({
    open: false,
    state: "",
    msg: "",
  });
  const [phonenumber, setPhonenumber] = useState("");
  const [sendProgramData, setSendProgramData] = useState<StudioProgramType>();

  //api
  const param = {
    page: clickPage,
    usridx: usridx, //235,
    order: orderType,
    libraryGroup: studioPrograms,
    companyIdx: companyIdx,
  };
  const useStudioProgramstateApi = useStudioProgramstate(param);
  const usePhonecheckeApi = usePhonechecke({
    usrphone: phonenumber,
    programidx: Number(programidx),
  });
  const useSendexerciseApi = useSendexercise({
    usridx: usePhonecheckeApi?.data?.usridx,
    usrphone: phonenumber,
    sendusridx: usridx,
    programIdx: sendProgramData?._id,
    category: sendProgramData?.category,
    libraryGroup: sendProgramData?.libraryGroup,
    companyIdx: companyIdx,
  });

  useEffect(() => {
    const page = searchGetPage(); //주소줄에서 선택한 page값 추출
    setClickPage(page);
  }, [window.location.pathname, window.location.search]);

  useEffect(() => {
    if (usridx && clickPage > 0) useStudioProgramstateApi.refetch();
  }, [orderType, clickPage, usridx]);

  //클라통신 프로그램 갱신
  useEffect(() => {
    if (programReload === "True") {
      useStudioProgramstateApi.refetch();
    }
  }, [programReload]);

  useEffect(() => {
    if (isReLoad && !useStudioProgramstateApi.isLoading)
      useStudioProgramstateApi.refetch();
  }, [isReLoad]);

  useEffect(() => {
    const { data, isSuccess, isLoading } = useStudioProgramstateApi;
    setReLoad(!isLoading);
    if (isSuccess && data?.result === "success") {
      if (data?.totalProgramCount < 1) {
        props.setProduceFirst(true);
      }
      setDataArr(data?.exerciseProgramList);
      setPageNavState({
        page: 5,
        dateLength: data?.totalProgramCount,
      });
      dispatch(set_program_reload(false));
    }
  }, [
    useStudioProgramstateApi.data,
    useStudioProgramstateApi.isSuccess,
    useStudioProgramstateApi.isLoading,
  ]);

  useEffect(() => {
    const { data, isSuccess } = usePhonecheckeApi;
    if (isSuccess && data?.result === "success") {
      const { user } = data;
      if (user === "correct") {
        useSendexerciseApi.refetch();
        setSubmitModal({
          open: false,
          state: "",
          msg: "",
        });
      } else {
        setSubmitModal({
          ...submitModal,
          state: "error",
          msg: t("Studio_Exercise_Send_Pop_Text_5"),
        });
      }
    }
  }, [usePhonecheckeApi.data, usePhonecheckeApi.isSuccess]);

  useEffect(() => {
    const { data, isSuccess } = useSendexerciseApi;
    if (isSuccess && data?.result === "success") {
      if (data?.exercise === "overlap") {
        let alertModal = {
          onoff: true,
          title: t("Kiosk_Common_Failure_Text"),
          text: t("Trainer_SettingPage_Fail_Pop_Msg_10"),
        };
        dispatch(set_alert_modal(alertModal));
      } else {
        let alertModal = {
          onoff: true,
          title: t("Trainer_SettingPage_Success_Pop_Title"),
          text: t("Studio_Exercise_Send_Pop_Text_3"),
        };
        dispatch(set_alert_modal(alertModal));
      }
    }
    useSendexerciseApi.remove();
  }, [useSendexerciseApi.data, useSendexerciseApi.isSuccess]);

  //페이지
  const handleClickPage = (num: number) => {
    const url = pageSearchAddFunc(num);
    navigate(url);
  };

  const handleClickSortBtn = (type: string, data: number) => {
    let sort: SortType = { date: 0, time: 0 };
    sort[type as keyof SortType] = data;

    let order = "descupdateDate";
    if (type === "date") {
      order = data === 1 ? "descupdateDate" : "ascupdateDate";
    } else if (type === "time") {
      order = data === 1 ? "descmyexerciseTime" : "ascmyexerciseTime";
    }
    setSortDatas(sort);
    setOrderType(order);
  };

  const handlePhonenumber = (value: string) => {
    setSubmitModal({
      ...submitModal,
      state: "",
      msg: "",
    });
    const phonenumber = onlyNumberFunc(value);
    if (phonenumber.length < 12) {
      setPhonenumber(phonenumber);
    }
  };

  const handleSubmitModal = (data: StudioProgramType) => {
    setSubmitModal({ open: true, state: "", msg: "" });
    setSendProgramData(data);
    usePhonecheckeApi.remove();
    setPhonenumber("");
  };

  const onSubmit = () => {
    if (phonenumber.length < 6) {
      setSubmitModal({
        ...submitModal,
        state: "error",
        msg: t("Studio_Exercise_Send_Pop_Text_4"),
      });
    } else {
      usePhonecheckeApi.refetch();
    }
  };

  return (
    <div className={`produceTable`}>
      {dataArr && dataArr.length > 0 && (
        <div className={`listTop`}>
          <p className={`produceListImg`}></p>
          <p className={`body_medium title`}></p>
          <p className={`th btn`}>
            <SortBtn
              text={t("ExerciseStudioPage_MakingList_Column_Name_1")}
              sort={sortDatas.date}
              click={handleClickSortBtn}
              type="date"
            />
          </p>
          <i className={`line`}></i>
          <p className={`caption3_regular th`}>
            <SortBtn
              text={t("ExerciseStudioPage_MakingList_Column_Name_2")}
              sort={sortDatas.time}
              click={handleClickSortBtn}
              type="time"
            />
          </p>
          <div className={`btns`}></div>
        </div>
      )}
      <div className={`produceTbody`}>
        {useStudioProgramstateApi.isLoading ? (
          noDataArr.map((_, index: number) => (
            <React.Fragment key={index}>
              <LodingProduceList />
            </React.Fragment>
          ))
        ) : dataArr && dataArr.length > 0 ? (
          dataArr.map((data: any, index: number) => (
            <React.Fragment key={index}>
              <ProduceList
                data={data}
                setReLoad={setReLoad}
                setClickPage={setClickPage}
                userInfo={headerInfo}
                handleSubmitModal={handleSubmitModal}
              />
            </React.Fragment>
          ))
        ) : (
          <div className={`noDataArea`}>
            <NoData text={t("ExerciseStudioPage_MakingList_Msg")} />
          </div>
        )}
      </div>
      {dataArr && dataArr.length > 0 && (
        <div className={`pageArea`}>
          <PageNav
            clickPage={clickPage}
            itemsCountPerPage={pageNavState.page}
            totalItemsCount={pageNavState?.dateLength}
            pageRangeDisplayed={10}
            handleClickPage={handleClickPage}
          />
        </div>
      )}
      <InputModal
        open={submitModal.open}
        desc={t("Studio_Exercise_Send_Pop_Text_1")}
        title={"Studio_Exercise_Send_Button"}
        placeholder={"ex) 01012345678"}
        onSubmit={onSubmit}
        onCancle={() => setSubmitModal({ open: false, state: "", msg: "" })}
        state={submitModal.state}
        msg={submitModal.msg}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handlePhonenumber(e.target.value)
        }
        value={phonenumberFunc(phonenumber)}
      />
    </div>
  );
};

export default ProduceTable;
