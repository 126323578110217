const DEV_URL = "http://metagymdev.exbody.com:49883";
// const DEV_URL = "http://192.168.0.100:49883";  //
const LIVE_URL = "https://api.kiosk.gym.metafamily.world";
const QA_URL = "https://api.kiosk.gym.qa.metafamily.world";
//"http://172.16.30.11:49883"

export const URL = window.location.origin.includes("gym.metafamily.world")
  ? LIVE_URL
  : window.location.origin.includes("qa")
  ? QA_URL
  : DEV_URL;

export const selecDayList = [
  {
    id: "lately7",
    text: "Trainer_Page_Setting_Member_Pop_Term_1",
    type: "week",
  },
  {
    id: "lately28",
    text: "Trainer_Page_Setting_Member_Pop_Term_2",
    type: "28day",
  },
  {
    id: "lately3",
    text: "Trainer_Page_Setting_Member_Pop_Term_3",
    type: "3month",
  },
  {
    id: "lately6",
    text: "Trainer_Page_Setting_Member_Pop_Term_4",
    type: "6month",
  },
  {
    id: "lately1",
    text: "Trainer_Page_Setting_Member_Pop_Term_5",
    type: "year",
  },
];

export const selectExerciseCategoryList = [
  {
    id: "exerciseCategory1",
    text: "ExerciseStudioPage_UploadList_DropDownBtn_1",
    type: "ALL",
  },
  { id: "exerciseCategory2", text: "Trainer_Type_1", type: "PT" },
  { id: "exerciseCategory3", text: "Trainer_Type_2", type: "GX" },
  { id: "exerciseCategory4", text: "Trainer_Type_3", type: "LIVE" },
  {
    id: "exerciseCategory5",
    text: "MyInfo_SurveyInfoTab_InputSubTitle_8",
    type: "ETC",
  },
];
