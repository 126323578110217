import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RootState } from "modules";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { set_creator_index } from "modules/local";

//style
import "./ExerciseDetail.scss";

//img
import { ReactComponent as WarningCircleLined } from "assets/svg/ic_warning_circle_lined.svg";

//global function
import { dataArrSetting } from "utils/commons";
import { cAPIMessagePack } from "utils/cAPI";

//data
import { exerciseTools2, bodyCommonCode } from "datas/Exercise";

//component
import ExerciseDay from "components/infos/exerciseDay/ExerciseDay";
import TypeTooltip from "components/commons/typeTooltip/TypeTooltip";
import ExStartBtn from "components/butttons/exStartBtn/ExStartBtn";
import BackLayout from "components/backLayout/BackLayout";
import VideoSymbol from "components/symbol/VideoSymbol";
import KEMASymbol from "components/symbol/KEMASymbol";

// api
import { useExerciseDetail } from "apis/exerciseApi";
import SchoolSymbol from "components/symbol/SchoolSymbol";

const ExerciseDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state: RootState) => state);
  const usridx = store.local.usridx;
  const navigate = useNavigate();
  const [dayArr, setDayArr] = useState<number[] | null>(null); //날짜 표시
  const [programDatas, setProgramDatas] = useState<ProgramDataType | null>(
    null
  );
  const [tagArr, setTagArr] = useState<string[] | null>(null);
  const [bodyTagArr, setBodyTagArr] = useState<any[] | null>(null);
  const [toolTagArr, setToolTagArr] = useState<any[] | null>(null);
  const [exWeekDataArr, serExWeekDataArr] = useState<any[] | null>(null); //주차 운동 정보
  const [openWeekDay, setOpenWeekDay] = useState({ week: 1, day: 0 });
  const [today, setToday] = useState({ week: 1, day: 0 });
  const [commonCodeArr, setCommonCodeArr] = useState<any[] | null>(null);
  const [countTypeArr, setCountTypeArr] = useState<any[] | null>(null);
  const [exCode, setExCode] = useState("");

  //api
  const detailParam = {
    _id: exCode,
    usridx: usridx,
  };
  const useExerciseDetailApi = useExerciseDetail(detailParam);

  useEffect(() => {
    const { data, isSuccess } = useExerciseDetailApi;
    if (isSuccess) {
      setProgramDatas(data?.programData);
      const reDataArr = dataArrSetting(
        data?.programData.daysAWeek,
        data?.exerciseData
      );
      const reDoneDataArr = dataArrSetting(
        data?.programData.daysAWeek,
        data?.exerciseDone
      );
      serExWeekDataArr(reDataArr);
      setDayArr(reDoneDataArr);
      setCommonCodeArr(data?.commonCode);
      if (data?.tagArr) setTagArr(data?.tagArr);
    }
  }, [useExerciseDetailApi.data, useExerciseDetailApi.isSuccess]);

  useEffect(() => {
    const excode = window.location.search
      .replace("&type=studio", "")
      .split("?excode=");
    setExCode(excode[1]);
    dispatch(set_creator_index(0));
  }, [window.location.pathname]);

  useEffect(() => {
    if (exCode && exCode !== "") {
      useExerciseDetailApi.refetch();
    }
  }, [exCode, usridx]);

  useEffect(() => {
    if (!usridx) {
      setOpenWeekDay({ week: 1, day: 1 });
      setToday({ week: 1, day: 0 });
      return;
    }

    const getTodayIndex = () => {
      const startIndex = weekDayFunc();

      if (!programDatas) return { week: 1, day: 0 };

      if (
        startIndex.week === programDatas.weeks &&
        startIndex.day === programDatas.daysAWeek
      ) {
        return { week: 1, day: 0 };
      } else if (startIndex.day + 1 > programDatas.daysAWeek) {
        return { week: startIndex.week + 1, day: 1 };
      } else {
        return { week: startIndex.week, day: startIndex.day + 1 };
      }
    };

    const todayIndex = getTodayIndex();

    setOpenWeekDay(todayIndex);
    setToday(todayIndex);
  }, [dayArr, usridx, programDatas]);

  useEffect(() => {
    if (!commonCodeArr || !programDatas) return;

    const mainTag = programDatas?.exerciseMainTagId.sort();
    const tagArray: string[] = [];
    const bodyArr: any[] = [];
    const toolArr: any[] = [];
    const codeTypeArr: any[] = [];

    mainTag.forEach((tag) => {
      commonCodeArr.forEach((element) => {
        // Count Type 처리
        if (element[0] === "countType") {
          const type = {
            key: element[1],
            title: element[4],
            title2: element[3],
          };

          // 중복 체크 (key를 기준으로)
          if (!codeTypeArr.some((item) => item.key === type.key)) {
            codeTypeArr.push(type);
          }
        }

        // Tag Array 처리
        if (
          (programDatas.category && programDatas.category > 1) ||
          (programDatas.category === 1 && programDatas.libraryGroup === 9)
        ) {
          if (tag.toString() === element[1]) {
            tagArray.push(element[4]);
          }
        } else if (element[0] === "exerciseMainTagId") {
          // Body Tag 처리
          if (element[3] === 2 && tag.toString() === element[1]) {
            const newData = {
              id: element[1],
              title: element[2],
              title2: element[4],
              icon:
                bodyCommonCode.find((body) => body.text === element[4])?.icon ||
                {},
            };
            bodyArr.push(newData);
          }

          // Tool Tag 처리
          if (element[3] === 3 && tag.toString() === element[1]) {
            const newData = {
              id: element[1],
              title: element[2],
              title2: element[4],
              icon:
                exerciseTools2.find((tool) => tool.text === element[4])?.icon ||
                {},
            };
            toolArr.push(newData);
          }
        }
      });
    });

    setTagArr(tagArray);
    setBodyTagArr(bodyArr.sort());
    setToolTagArr(toolArr);
    setCountTypeArr(codeTypeArr);
  }, [commonCodeArr, programDatas]);

  const dayClickFunc = (week: number, day: number) => {
    setOpenWeekDay({ week: week, day: day });
  };

  const exStartBtn = () => {
    if (
      (programDatas?.category && programDatas?.category > 1) ||
      (programDatas?.category === 1 && programDatas?.libraryGroup === 9)
    ) {
      dispatch(set_creator_index(programDatas?.creatorIdx));
      navigate(
        `/videoEXStart?week=${openWeekDay?.week}&day=${openWeekDay?.day}&_id=${exCode}`
      );
    } else {
      const strMsg = `exbodyKiosk:?type=1&_id=${exCode}&usridx=${usridx}&weeks=${today.week}&daysAWeek=${today.day}`;
      cAPIMessagePack(strMsg);
    }
  };

  const weekDayFunc = () => {
    let lastDoneIndex = { week: 1, day: 0 };
    dayArr?.forEach((day: any, index: number) => {
      day?.forEach((day: any, index1: number) => {
        if (day === 1) {
          lastDoneIndex = { week: index + 1, day: index1 + 1 };
        }
      });
    });
    return lastDoneIndex;
  };

  return (
    <BackLayout maxWidth="100%" bgColor="black2">
      <div id="exerciseDetail" className={`exerciseDetail`}>
        <div className={`blurArea`}>
          <p className={`blurImg`}>
            {programDatas?.exerciseImage && (
              <img
                src={
                  programDatas.exerciseImage.includes("http")
                    ? programDatas.exerciseImage
                    : `${programDatas.exerciseImage}.png`
                }
                alt="img"
              />
            )}
          </p>
          <p className={`blur`}></p>
        </div>
        <div className={`exerciseDetailContainer`}>
          <div className={`exerciseDetailBox`}>
            {programDatas?.libraryGroup === 5 && <VideoSymbol />}
            {programDatas?.libraryGroup === 6 && <KEMASymbol />}
            {programDatas?.libraryGroup === 9 && <SchoolSymbol />}

            <div className={`infoArea`}>
              <p
                className={programDatas?.exerciseImage ? `img` : `img no_data2`}
              >
                {programDatas?.exerciseImage && (
                  <img
                    src={
                      programDatas.exerciseImage.includes("http")
                        ? programDatas.exerciseImage
                        : `${programDatas.exerciseImage}.png`
                    }
                    alt="img"
                  />
                )}
              </p>
              <div className={`exerciseInfoArea scroll whiteBackScroll`}>
                {(programDatas?.category && programDatas?.category > 1) ||
                (programDatas?.category === 1 &&
                  programDatas?.libraryGroup === 9) ? (
                  <div className={`exerciseToolBodyList`}>
                    {tagArr &&
                      tagArr?.map((name) => (
                        <span className={`icon caption1_medium`} key={name}>
                          {t(name)}
                        </span>
                      ))}
                  </div>
                ) : (
                  <>
                    <div className={`exerciseToolBodyList`}>
                      <p className={`caption1_regular title`}>
                        {t("ExercisePage_ContentDetail_ExerciseTool_Title")}
                      </p>
                      <div className={`exerciseDescBox tool`}>
                        {toolTagArr ? (
                          toolTagArr.map((desc: any, index: number) => (
                            <div key={`tooltip_${index}`} className={`tooltip`}>
                              <TypeTooltip
                                text={t(desc.title2)}
                                icon={desc.icon}
                              />
                            </div>
                          ))
                        ) : (
                          <p className={`tooltip no_data2`}></p>
                        )}
                      </div>
                    </div>
                    <div className={`exerciseToolBodyList body`}>
                      <p className={`caption1_regular title`}>
                        {t(
                          "ExercisePage_ContentDetail_ExerciseBodyParts_Title"
                        )}
                      </p>
                      <div className={`exerciseDescBox body`}>
                        {bodyTagArr ? (
                          bodyTagArr.map((desc: any, index: any) => (
                            <div key={`tooltip_${index}`} className={`tooltip`}>
                              <TypeTooltip
                                text={t(desc.title2)}
                                img={desc.icon}
                              />
                            </div>
                          ))
                        ) : (
                          <p className={`tooltip no_data2`}></p>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <p className={`d1_bold programTitle`}>{programDatas?.title}</p>
            <pre className={`body_regular programInfo`}>
              {programDatas?.programInfo}
            </pre>
            <div className={`profileStart`}>
              {usridx > 0 ? (
                <ExStartBtn action={true} onClick={exStartBtn} />
              ) : (
                <Link className={`exStartBtnLink`} to={`/login`}>
                  <ExStartBtn action={true} />
                </Link>
              )}
            </div>
            <div className={`body_medium waringArea`}>
              <p className={`caption1_medium wraringIcon`}>
                <WarningCircleLined />
                {t("ExercisePage_ContentDetail_Precautions")}
              </p>
              {/* 주의사항 */}
              <pre className={`body_medium`}>{programDatas?.precaution}</pre>
            </div>
            <div className={`dayDescArea`}>
              <div className={`dayArea`}>
                {dayArr?.map((day: any, index: number) => (
                  <div key={`dayArea${index}`}>
                    <ExerciseDay
                      open={openWeekDay}
                      week={index + 1}
                      dayClickFunc={dayClickFunc}
                      data={day}
                      daysAWeek={programDatas?.daysAWeek}
                      today={today}
                      exData={exWeekDataArr && exWeekDataArr[index]}
                      programDatas={programDatas}
                      countTypeArr={countTypeArr}
                      exCode={exCode}
                      usridx={usridx}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackLayout>
  );
};

export default ExerciseDetail;
